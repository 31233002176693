import React, { useState, useEffect, useContext } from "react"
import classnames from 'classnames'
import queryString from 'query-string';
import Layout from "layouts/layout";
import Seo from "components/seo"
import { patchUserCertification } from 'api/index';
import {
  certifiedResultContainer
} from './index.module.less';

// 认证激活页面
const certifiedResult = () => {
  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    const code = parsed?.code;
    activateCertification(code);
  }, [])

  // 激活用户的企业认证
  const activateCertification = (code: any) => {
    patchUserCertification({code})
      .then(() => {
        setLoading(false);
        setStatus(true);
        setTimeout(() => {
          window.location.href = '/account/person.html';
        }, 3000);
      })
      .catch(() => {
        setLoading(false);
        setStatus(false);
        // setTimeout(() => {
        //   window.location.href = '/account/person.html';
        // }, 2000);
      });
  };

  return (
    <Layout>
      <Seo 
        title="企业认证激活"
        description=""
        keywords=""
        saTitle="">
      </Seo>
      <div className={certifiedResultContainer}>
        {loading && <p className={classnames("status-info loading")}>
          正在激活。。。
        </p>}
        {!loading && <p className={classnames("status-info", {'success': status, 'failed': !status})}>
          {status ? <>恭喜！你已完成认证，返回神策<br className="hidden-pc" />个人中心登录后查看认证状态</> : <>激活链接已过期，请登录神策<br className="hidden-pc" />个人中心重置认证</>}
        </p>}
      </div>
    </Layout>
  );
}

export default certifiedResult; 